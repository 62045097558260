.contact {
  display: flex;
  flex-direction: row;
  margin: auto;
  margin-top: 20px;
  font-size: 11px;
  align-items: center;
  padding: 12px 32px;
  gap: 10px;
  color: white;
  width: 119.84px;
  height: 39.07px;
  text-decoration: none;
  left: 514px;
  top: 1435.16px;
  border: none;
  background: #ffc727;
  border-radius: 6px;
}
.plan-features ul {
  list-style: none;
  padding: 0;
}

/* BUTTON SWITCH */
label.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  margin-top: 0;
  margin-bottom: 0px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 51px;
  background-color: #ccc;
  border-radius: 50px;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 19px;
  width: 22px;
  left: -1px;
  top: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50px;
}

input:checked + .slider {
  background-color: #66676b;
}

input:checked + .slider:before {
  transform: translateX(30px);
}
