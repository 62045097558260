/* .right-container {
  width: 70%;
} */
/* .left-container {
  width: 35%;
}

.user-box {
  border: 1px solid gray;
  padding: 10px;
  margin: 10px;
  background-color: black;

  border-radius: 5px;
  box-sizing: border-box;
  max-width: 100%;
}
.user-name-link-hover:hover {
  background-color: #61b2db;
}
.user-info-box {
  background-color: #f2f2f2;
  padding: 25px;
  border-radius: 5px;
  box-sizing: border-box;
  max-width: 100%;
}

.highlighted {
  background-color: #61b2db;
  color: white;
  padding: 2px 10px;
  border-radius: 50px;
} */
.messages-container {
  margin: 0;
  box-shadow: 0 0 12px 0 rgb(0 0 0 / 6%);
  border-radius: 4px;
  background-color: #fff;
}
.messages-headline {
  font-size: 16px;
  margin: 0;
  padding: 22px 30px;
  color: #333;
  background-color: #fff;
  display: block;
  border-bottom: 1px solid #eaeaea;
  border-radius: 4px 4px 0 0;
  position: relative;
}
.messages-headline h4 {
  font-size: 16px;
  color: #333;
  padding: 0;
  margin: 0;
  font-weight: 600;
  display: inline-block;
}
.messages-container-inner {
  display: flex;
}
@media only screen and (max-width: 768px) {
  .messages-container-inner {
    display: block;
  }
}
.messages-container-inner .messages-inbox {
  max-width: 340px;
  border-right: 1px solid #eaeaea;
}

.messages-container-inner .messages-inbox {
  overflow: hidden;
}
.messages-container-inner .messages-inbox,
.messages-container-inner .message-content {
  flex: 1;
}
.messages-container-inner .messages-inbox ul {
  max-height: 800px;
  overflow-y: scroll;
  width: 357px;
}
.messages-inbox ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.messages-inbox ul li {
  border-bottom: 1px solid #eaeaea;
  transition: 0.2s;
}
.messages-container-inner .messages-inbox ul li a {
  padding: 25px;
}

.messages-inbox ul li a {
  position: relative;
  display: block;
  padding: 30px;
}
.messages-container-inner .messages-inbox .message-avatar {
  left: 25px;
}
.messages-inbox .message-avatar {
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
}
.messages-container-inner .message-avatar img {
  width: 40px;
  height: 40px;
}

.message-avatar img {
  display: inline-block;
  width: 54px;
  height: 54px;
  border-radius: 50%;
}
.messages-container-inner .messages-inbox .message-by {
  margin-left: 60px;
}

.messages-inbox .message-by {
  margin-left: 85px;
  font-size: 15px;
  color: #666;
}
.messages-container-inner .messages-inbox .message-by p {
  height: 26px;
  max-width: 205px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  color: #888;
}

.messages-inbox .message-by p {
  margin: 0;
  padding: 0;
  line-height: 27px;
  font-size: 15px;
}
.messages-inbox ul li.active-message {
  background-color: #e0e0e0;
}
.messages-inbox ul li:last-child {
  border-bottom: none;
}
.messages-container-inner .messages-inbox ul {
  max-height: 800px;
  overflow-y: scroll;
  width: 357px;
}
.messages-inbox ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.messages-container-inner .messages-inbox {
  max-width: 340px;
  border-right: 1px solid #eaeaea;
}
.messages-container-inner .messages-inbox {
  overflow: hidden;
}
.messages-container-inner .message-content {
  padding: 30px;
}
.messages-container-inner .messages-inbox,
.messages-container-inner .message-content {
  flex: 1;
}
.dashboard-list-box {
  margin: 30px 0 0 0;
  box-shadow: 0 0 12px 0 rgb(0 0 0 / 6%);
  border-radius: 4px;
}
.dashboard-list-box ul {
  list-style: none;
  padding: 0;
  margin: 0;

  border-radius: 0 0 4px 4px;
}
.dashboard-list-box ul li:last-child {
  border: none;
}
.dashboard-list-box ul li {
  padding: 6px 9px;
  border-bottom: 1px solid black;
  transition: 0.3s;
  position: relative;
}
.bookings {
  margin: 10px 0;
}
.list-box-listing {
  display: flex;
  padding: 5px 0;
  position: relative;
}
.bookings .list-box-listing-img {
  margin-right: 5px;
}

.bookings .list-box-listing-img {
  max-width: 70px;
  max-height: 70px;
  border-radius: 50%;
  overflow: hidden;
}
.list-box-listing-content {
  flex: 1;
  padding-left: 25px;
}
*,
:after,
:before {
  box-sizing: border-box;
}
.messages-inbox ul li.active-message {
  background-color: #e0e0e0;
}
.inner-booking-list h5 {
  display: inline-block;
  font-weight: 600;
  margin: 0;
  color: #666;
}
.dashboard-list-box ul ul {
  background-color: transparent;
}

.inner-booking-list ul {
  display: inline-block;
  padding: 0;
  margin-bottom: 0;
}
.pending-booking .inner-booking-list ul li.highlighted {
  background-color: #e9f7fe !important;
  color: #3184ae;
}
.dashboard-list-box ul ul li:nth-last-child(2n + 1) {
  background-color: transparent;
}
.inner-booking-list ul li.highlighted {
  padding: 2px 10px;
  line-height: 22px;
  font-weight: 500;
  font-size: 14px;
  background-color: #eee !important;
  color: #777;
  border-radius: 50px;
  display: inline-block;
}
.inner-booking-list ul {
  display: inline-block;
  padding: 0;
  margin-bottom: 0;
}
.pending-booking .bookings .inner h3 span.booking-status {
  background-color: #61b2db;
}
.bookings .inner h3 span.booking-status {
  border-radius: 50px;
  line-height: 20px;
  font-weight: 600;
  font-size: 12px;
  color: #fff;
  font-style: normal;
  padding: 2px 8px;
  margin-left: 3px;
  position: relative;
  top: -2px;
}
/* .user-name-link-hover:hover {
  background-color: #61b2db;
} */
.selected {
  background-color: #e6e6e6;
  color: #333;
}
