.editProfileContentWrap .generalFormWrap {
  background: #fff;
  border-radius: 10px;
  padding: 38px 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 62px;
}
.editProfileContentWrap .editFormHeading {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  text-transform: capitalize;

  color: #000;
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.3);
  padding-bottom: 18px;
  width: 100%;
  margin-bottom: 35px;
}
.editProfileContentWrap .editFormHeading {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  text-transform: capitalize;

  color: #000;
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.3);
  padding-bottom: 18px;
  width: 100%;
  margin-bottom: 35px;
}
@media (min-width: 1200px) {
  .h3,
  h3 {
    font-size: 1.75rem;
  }
}

.editProfileContentWrap .customFormGrp {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 25px;
  width: 100%;
  -webkit-align-items: center;
  align-items: center;
}
.editProfileContentWrap .editMyInfo .customFormGrp label {
  width: 35%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
.editProfileContentWrap .customFormGrp label {
  width: 25%;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;

  letter-spacing: 0.1px;
  color: #000;
}
label {
  display: inline-block;
}
.editProfileContentWrap .editMyInfo .customFormGrp .formControlWrap {
  width: 65%;
}

.editProfileContentWrap .customFormGrp .formControlWrap {
  width: 75%;
}
.editProfileContentWrap .btnFormGrp {
  margin: 0 auto;
  -webkit-justify-content: center;
  justify-content: center;
}
.editProfileContentWrap .generalFormWrap {
  background: #fff;
  border-radius: 10px;
  padding: 38px 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 62px;
}
.editProfileContentWrap .editMyInfo .customFormGrp label img {
  width: 47px;
  height: 47px;
  display: -webkit-flex;
  display: flex;
  margin: 0 22px 0 auto;
}
.editProfileContentWrap .customFormControl {
  padding: 21px 14px;
  background: #f9f9f9;
  border: 1px solid #e6e6e6;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 5px;
  height: 50px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  /* text-transform: capitalize; */
  color: #000;
  text-align: left;
  margin-bottom: 0;
  width: 100%;
  display: block;

  letter-spacing: 0.2px;
  opacity: 1;
}

.editProfileContentWrap .editProfileSubmitBtn {
  background: #000;
  border-radius: 77px;
  height: 54px;
  border: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;

  letter-spacing: 0.5px;
  color: #fff;
  /* padding: 0 42px; */
}
@media (min-width: 180px) and (max-width: 480px) {
  .editProfileContentWrap .generalFormWrap {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  form {
    /* width: 80vw; */
    min-width: 0;
    min-width: auto;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .editProfileContentWrap .customFormGrp {
    -webkit-flex-direction: column;
    flex-direction: column;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .editProfileContentWrap .customFormGrp {
    margin-bottom: 15px;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .editProfileContentWrap .editMyInfo .customFormGrp label {
    -webkit-align-items: center !important;
    align-items: center !important;
  }
}

@media (min-width: 180px) and (max-width: 480px) {
  .editProfileContentWrap .customFormGrp label {
    width: 100% !important;
    margin: 0 0 7px;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .editProfileContentWrap .customFormGrp label {
    width: 100%;
    margin-bottom: 6px;
  }
}
@media (min-width: 180px) and (max-width: 480px) {
  .editProfileContentWrap .editMyInfo .customFormGrp label img {
    margin: 0 22px !important;
    margin-left: unset;
    display: none;
  }
}

@media (min-width: 180px) and (max-width: 480px) {
  .editProfileContentWrap .customFormGrp .formControlWrap {
    width: 100% !important;
  }
}

.fieldNote {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0.2px;
  color: #737373;

  display: inline-block;
  margin-top: 5px;
}

/* Form VEndor */
.editProfileContentWrap .customFormGrp label {
  width: 25%;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;

  letter-spacing: 0.1px;
  color: #000;
}

label {
  display: inline-block;
}
.editProfileContentWrap .customFormGrp .formControlWrap {
  width: 75%;
}
.editProfileContentWrap textarea.customFormControl {
  height: 212px;
  resize: none;
}
.editProfileContentWrap .customFormGrp .formControlWrap {
  width: 75%;
}
.customCheckboxWrap {
  display: -webkit-flex;
  display: flex;
  width: 75%;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media (min-width: 481px) and (max-width: 767px) {
  .editProfileContentWrap .generalFormWrap {
    width: 100%;
  }
}
.editProfileContentWrap .customFormGrp label .redDot {
  color: #cb0000;
  margin-left: 2px;
}
.radio-wrapper {
  display: inline-flex;
  background: transparent;
  height: 100px;
  width: 180px;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
  padding: 20px 0px;
}
.radio-wrapper input[type='radio'] {
  display: none;
}
input[type='radio'] {
  width: 15px;
  height: 15px;
  cursor: pointer;
  box-shadow: none;
}
.radio-wrapper #option-1:checked:checked ~ .option-1,
.radio-wrapper #option-2:checked:checked ~ .option-2,
.radio-wrapper #option-3:checked:checked ~ .option-3 {
  background: lightgray;
  /* border-color: #edb300; */
  /* background: #edb300; */
}
.radio-wrapper .option {
  background: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 10px 0 0;
  border-radius: 5px;
  cursor: pointer;
  padding: 0 10px;
  border: 2px solid lightgrey;
  transition: all 0.3s ease;
}
@media (min-width: 180px) and (max-width: 480px) {
  .editProfileContentWrap .customFormGrp {
    flex-direction: column;
    margin-bottom: 15px;
  }
}
.editProfileContentWrap .customFormGrp {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
  width: 100%;
}
