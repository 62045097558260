.pagination {
  justify-content: end;
}
.pagination > li {
  display: inline-block;
  padding-left: 0;
}
.pagination > li {
  list-style: none;
  border: 0.9px solid;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #2c689c;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
}

.pagination > li.active > a {
  color: #fff;
  background-color: skyblue;
  border-color: skyblue;
}

.pagination > li > a:hover {
  background-color: skyblue;
  color: white;
}
.pagination-number {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
}
.pagination > li.disabled > a {
  background-color: gray;
  pointer-events: none;
  cursor: default;
}
